<template>
  <div class="information page-container">
    <div class="information-header">
      <div class="title">衢州市广纳人才开发有限公司会费收取服务指南</div>
      <div class="sub-title">
        <span class="time">发布时间：2010-12-30</span>
      </div>
    </div>
    <div class="information-content" >
      <div v-html="content" />
    </div>
  </div>
</template>
<script>

let content = `<p class="MsoNormal" style="background:#FFFFFF;text-align:left;">
<span style="color:#000000;font-family:宋体;font-size:10.5pt;">衢州市广纳人才开发有限公司承办衢州人才网，为各类企事业单位提供人力资源市场招聘信息服务，会员服务收费标准如下：</span><span style="color:#777777;font-family:宋体;font-size:10.5pt;"></span> 
</p>
<p class="MsoNormal" style="background:#FFFFFF;text-align:left;text-indent:21pt;">
<span style="color:#E53333;font-family:宋体;font-size:10.5pt;font-weight:bold;">（一）会员会费标准为月标准<span>150</span><span>元，普通会员年标准</span><span>800</span><span>元</span><span>。会员免费享受衢州人才网网络招聘服务、市人力资源市场人才招聘优惠服务</span></span><span style="color:#E53333;font-family:宋体;font-size:10.5pt;font-weight:bold;"></span> 
</p>
<p class="MsoNormal" style="background:#FFFFFF;text-align:left;text-indent:10.5pt;margin-left:5.25pt;">
<span style="color:#000000;font-family:Tahoma;font-size:10.5pt;"><strong>（二）办理</strong></span><span style="color:#000000;font-family:宋体;font-size:10.5pt;"><strong>会员服务</strong></span><span style="color:#000000;font-family:Tahoma;font-size:10.5pt;"><strong>方式与所需材料</strong></span><span style="color:#777777;font-family:Tahoma;font-size:10.5pt;"></span> 
</p>
<p class="MsoNormal" style="background:#FFFFFF;text-align:left;text-indent:21pt;">
<span style="color:#000000;font-family:Tahoma;font-size:10.5pt;">办理</span><span style="color:#000000;font-family:宋体;font-size:10.5pt;">会员服务</span><span style="color:#777777;font-family:Tahoma;font-size:10.5pt;"><span style="color:#000000;">方式可</span><span style="color:#000000;">“</span><span style="color:#000000;">现场办理</span><span style="color:#000000;">”</span><span style="color:#000000;">，也可</span><span style="color:#000000;">“</span><span style="color:#000000;">远程办理</span><span style="color:#000000;">”</span><span style="color:#000000;">。首次</span></span><span style="color:#000000;font-family:宋体;font-size:10.5pt;">申请会员</span><span style="color:#777777;font-family:Tahoma;font-size:10.5pt;"><span style="color:#000000;">服务必须</span><span style="color:#000000;">“</span><span style="color:#000000;">现场办理</span><span style="color:#000000;">”</span><span style="color:#000000;">。</span></span><span style="color:#777777;font-family:Tahoma;font-size:10.5pt;"></span> 
</p>
<p class="MsoNormal" style="background:#FFFFFF;text-align:left;text-indent:21pt;">
<span style="color:#E53333;font-family:Tahoma;font-size:10.5pt;font-weight:bold;">按照国税的统一规定，开票发票（浙江省增值税普通发票）须提供以下信息：开票单位名称、纳税人识别号、地址及电话、开户行及账号，如需开（浙江省增值税专用发票）须现场办理及提供开票代码6位数。</span> 
</p>
<p class="MsoNormal" style="background:#FFFFFF;text-align:left;">
<span style="color:#777777;font-family:Tahoma;font-size:10.5pt;">&nbsp;<span style="color:#000000;"> &nbsp; （</span><span style="color:#000000;">1</span><span style="color:#000000;">）现场办理（只接受刷卡、微信、支付宝，不接受现金）</span></span><span style="color:#777777;font-family:Tahoma;font-size:10.5pt;"></span> 
</p>
<p class="MsoNormal" style="background:#FFFFFF;text-align:left;text-indent:21pt;">
<span style="color:#000000;font-family:Tahoma;font-size:10.5pt;">首次办理的单位，请携带</span><span style="color:#000000;font-family:Tahoma;font-size:10.5pt;font-weight:bold;">营业执照副本原件及复印件（复印件加盖公章并保证其真实性）、开票信息</span><span style="color:#777777;font-family:Tahoma;font-size:10.5pt;"><span style="color:#000000;">，事业单位请携带法人登记证，直接到衢州市柯城区荷五路468号5楼504办公室</span><span style="color:#000000;">，网上招聘办事窗口缴费。</span></span> 
</p>
<p class="MsoNormal" style="background:#FFFFFF;text-align:left;text-indent:21pt;">
<span style="color:#000000;font-family:Tahoma;font-size:10.5pt;">招聘单位办理</span><span style="color:#000000;font-family:宋体;font-size:10.5pt;">会员</span><span style="color:#777777;font-family:Tahoma;font-size:10.5pt;"><span style="color:#000000;">续费服务，只需提供开票信息。（若遗忘账号</span><span style="color:#000000;">/</span><span style="color:#000000;">密码，同时需提供营业执照副本复印件；用人单位在招聘过程中不得随意要求更改单位名称；如单位名称发生变更，须提供工商部门出具的企业名称变更登记书。）</span></span><span style="color:#777777;font-family:Tahoma;font-size:10.5pt;"></span> 
</p>
<p class="MsoNormal" style="background:#FFFFFF;text-align:left;text-indent:15.75pt;">
<span style="color:#777777;font-family:Tahoma;font-size:10.5pt;"><span style="color:#000000;">（</span><span style="color:#000000;">2</span><span style="color:#000000;">）远程办理</span><span> </span><span style="color:#000000;">（银行转账，仅限办理续费服务）</span></span><span style="color:#003399;font-family:Tahoma;font-size:10.5pt;font-weight:bold;">（必须以开通单位的对公账号转入我单位对公账户）</span><span style="color:#777777;font-family:Tahoma;font-size:10.5pt;"></span> 
</p>
<p class="MsoNormal" style="background:#FFFFFF;text-align:left;text-indent:21pt;">
<span style="color:#777777;font-family:Tahoma;font-size:10.5pt;"><span style="color:#000000;">远程办理的单位以单位对公账号转入我单位对公账号（如下），转账后登入衢州人才网企业账号，在企业中心栏目下方</span><span style="color:#000000;">——</span><span style="color:#000000;">单位基本信息</span><span style="color:#000000;">——</span><span style="color:#000000;">申请续费服务，点击进入填写开票信息，上传银行的回单（</span></span><span style="color:#E53333;font-family:Tahoma;font-size:10.5pt;font-weight:bold;">网银转账的成功页面截图</span><span style="color:#777777;font-family:Tahoma;font-size:10.5pt;"><span style="color:#000000;">），可申请现场领取发票或者邮件发票（邮寄须注明地址，收件人，联系方式等），成功后请致电：0570-3086576、</span><span style="color:#000000;">0570-3080177</span><span style="color:#000000;">再次确认。我们在</span><span style="color:#000000;">3</span><span style="color:#000000;">个工作日内审核确认，开通办理。转账后待费用到帐，财务核对无误后（一般</span><span style="color:#000000;">15</span><span style="color:#000000;">个工作日），发票可至</span><span style="color:#000000;">501</span><span style="color:#000000;">办公室领取或进行免费邮寄。时间需两周或更长（视快递速度），敬请各用人单位注意查收，用人单位可在企业中心</span><span style="color:#000000;">——</span><span style="color:#000000;">申请续费记录中查看，</span><span style="color:#000000;">EMS</span><span style="color:#000000;">邮寄单号。</span></span><span style="color:#777777;font-family:Tahoma;font-size:10.5pt;"></span> 
</p>
<p class="MsoNormal" style="background:#FFFFFF;text-align:left;">
<span style="color:#003399;font-family:Tahoma;font-size:10.5pt;"><strong>户名：衢州市广纳人才开发有限公司</strong></span><span style="color:#777777;font-family:Tahoma;font-size:10.5pt;"><br />
</span><span style="color:#003399;font-family:Tahoma;font-size:10.5pt;"><strong>开户银行：中国农业银行衢州三衢路支行</strong></span><span style="color:#777777;font-family:Tahoma;font-size:10.5pt;"><br />
</span><span style="color:#777777;font-family:Tahoma;font-size:10.5pt;"><strong><span style="color:#003399;">帐</span></strong><span> </span><span style="color:#003399;"><strong>号：</strong></span><span style="color:#003399;"><strong>19720 5010 4000 4901</strong></span></span> 
</p>
<p class="MsoNormal" style="background:#FFFFFF;text-align:left;">
<span style="color:#777777;font-family:Tahoma;font-size:10.5pt;"><span style="color:#E53333;"><strong>注：</strong></span><span style="color:#E53333;"><strong>1</strong></span><span style="color:#E53333;"><strong>、汇款前务必先认真阅读《</strong></span></span><span style="color:#E53333;font-family:宋体;font-size:10.5pt;"><strong>会员</strong></span><span style="color:#E53333;font-family:Tahoma;font-size:10.5pt;"><strong>服务指南》，并与工作人员取得联系。请勿擅自汇款，否则不予退款；</strong></span> 
</p>
<p class="MsoNormal" style="background:#FFFFFF;text-align:left;">
<span style="color:#777777;font-family:Tahoma;font-size:10.5pt;"><span style="color:#E53333;"><strong>&nbsp; &nbsp; &nbsp; &nbsp;2</strong></span><span style="color:#E53333;"><strong>、请勿采用现金存入我单位帐号和个人账户转帐的结算方式。</strong></span></span><span style="color:#777777;font-family:Tahoma;font-size:10.5pt;"></span> 
</p>
<p class="MsoNormal" style="background:#FFFFFF;text-align:left;">
<span style="color:#777777;font-family:Tahoma;font-size:10.5pt;"><span style="color:#E53333;"><strong></strong></span><span style="color:#E53333;"><strong>&nbsp; &nbsp; &nbsp; &nbsp;3</strong></span><span style="color:#E53333;"><strong>、办理时间：周一至周五，夏令时：上午</strong></span><span style="color:#E53333;"><strong>8:30——12:00</strong></span><span style="color:#E53333;"><strong>；下午</strong></span><span style="color:#E53333;"><strong>2:30——5:30，冬令时：上午8:30——12:00；</strong></span><span style="color:#337FE5;"><span style="color:#E53333;"><strong>下午2:00——5:00（法定节假日除外</strong></span><span style="color:#E53333;"><strong>）</strong></span></span></span><span style="color:#777777;font-family:Tahoma;font-size:10.5pt;"></span> 
</p>
<p class="MsoNormal" style="background:#FFFFFF;text-align:left;">
<span style="color:#777777;font-family:Tahoma;font-size:10.5pt;"><span style="color:#000000;"><strong>&nbsp; &nbsp; （三）关于人才查询</strong></span><span style="color:#000000;"><strong>：</strong></span></span><span style="color:#777777;font-family:Tahoma;font-size:10.5pt;"></span> 
</p>
<p class="MsoNormal" style="background:#FFFFFF;text-align:left;">
<span style="color:#777777;font-family:Tahoma;font-size:10.5pt;">&nbsp; &nbsp; &nbsp; <span style="color:#000000;">用人单位如有招聘需求，可先在衢州人才网上查询所需人才信息，在没有办理</span></span><span style="color:#000000;font-family:宋体;font-size:10.5pt;">会员服务</span><span style="color:#000000;font-family:Tahoma;font-size:10.5pt;">手续获取账号之前无法看到个人的联系方式，如果找到合适人选可再来办理</span><span style="color:#000000;font-family:宋体;font-size:10.5pt;">会员</span><span style="color:#000000;font-family:Tahoma;font-size:10.5pt;">手续，办理了</span><span style="color:#000000;font-family:宋体;font-size:10.5pt;">会员服务</span><span style="color:#000000;font-family:Tahoma;font-size:10.5pt;">手续后，就可查阅包括姓名、联系方式在内的完整的人才求职信息。</span><span style="color:#777777;font-family:Tahoma;font-size:10.5pt;"></span> 
</p>
<p class="MsoNormal" style="background:#FFFFFF;text-align:left;">
<span style="color:#777777;font-family:Tahoma;font-size:10.5pt;"><span style="color:#000000;"><strong>&nbsp; &nbsp; （四）联系方式</strong></span><span style="color:#000000;"><strong>：</strong></span></span><span style="color:#777777;font-family:Tahoma;font-size:10.5pt;"><br />
</span><span style="color:#777777;font-family:Tahoma;font-size:10.5pt;">&nbsp; &nbsp;&nbsp;<span style="color:#000000;">地址：衢州市柯城区荷五路468号5楼504办公室 </span><span> </span><span style="color:#000000;">邮编：</span><span style="color:#000000;">324000</span></span><span style="color:#777777;font-family:Tahoma;font-size:10.5pt;"><br />
</span><span style="color:#777777;font-family:Tahoma;font-size:10.5pt;"><span style="color:#000000;">&nbsp; &nbsp; 电话：0570-3086576、</span><span style="color:#000000;">0570-</span><span style="color:#000000;">3080177；</span><span style="color:#000000;">传真：</span><span style="color:#000000;">0570-</span><span style="color:#000000;">3080177</span></span> 
</p>
<p class="MsoNormal" style="background:#FFFFFF;text-align:left;text-indent:10.5pt;">
<span><a><span style="color:#000000;font-family:Tahoma;font-size:10.5pt;text-decoration:underline;">E-mail</span></a></span><span><a><span style="color:#000000;font-family:Tahoma;font-size:10.5pt;text-decoration:underline;">：</span></a></span><span><a><span style="color:#000000;font-family:Tahoma;font-size:10.5pt;text-decoration:underline;">qzrc@21cn.net</span></a></span><span style="color:#777777;font-family:Tahoma;font-size:10.5pt;"> </span><span style="color:#000000;font-family:Tahoma;font-size:10.5pt;">网址：</span><span><a href="http://www.qzrcw.com/"><span style="color:#000000;font-family:Tahoma;font-size:10.5pt;text-decoration:underline;">http://www.qzrcw.com</span></a></span><span style="color:#777777;font-family:Tahoma;font-size:10.5pt;"></span> 
</p>
<p class="MsoNormal" style="background:#FFFFFF;text-align:left;text-indent:12pt;">
<span style="color:#E53333;font-family:Tahoma;font-size:10.5pt;"><strong>注：</strong></span><span style="color:#E53333;font-family:宋体;font-size:10.5pt;"><strong>会员</strong></span><span style="color:#E53333;font-family:Tahoma;font-size:10.5pt;"><strong>单位必须遵守有关的法律法规，如有求职者投诉至劳动监察支队或劳动仲裁部门，本网站有权停止向其提供</strong></span><span style="color:#E53333;font-family:宋体;font-size:10.5pt;"><strong>会员</strong></span><span style="color:#E53333;font-family:Tahoma;font-size:10.5pt;"><strong>服务，直到违法违规行为处理完毕，相关部门出具证明，方可重新开通</strong></span><span style="color:#E53333;font-family:宋体;font-size:10.5pt;"><strong>会员</strong></span><span style="color:#E53333;font-family:Tahoma;font-size:10.5pt;"><strong>服务。</strong></span><span style="color:#777777;font-family:Tahoma;font-size:10.5pt;"></span> 
</p>`

export default {
  name:"informationDetail",
  data:()=>({
    data:null
  }),
  created(){
    // this.getData()
  },
  methods:{
    getData() {
      this.$axiosRequest({
        name: "getValleyMessageDetail",
        ids:[this.$route.params.id]
      })
        .then((res) => {
          if (res.status === 200) {
            this.data = res.data;
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
  },
  computed:{
    content:()=>content
  },
  watch:{},

}
</script>
<style lang="scss">
@import "constants/style/public";
.information{
  text-align: left;
  font-size:$medium;
  color: $cardsubTextColor;
  .information-header{
    background-color: $defaultBg;
    padding:20px;
    display: flex;
    justify-content: space-between;
    flex-direction: column;
    box-shadow: 0px 0px 18px $shadowColor;
    .title {
      font-size: $extraLarge;
      font-weight: $weight-medium;
      padding-bottom: 16px;
      color: $titleColor;
    }
    .sub-title{
      color: $cardsubTextColor;
      .time{
        margin-right: 50px;
      }
    }
  }
  .information-content{
    min-height: 400px;
    background-color: $defaultBg;
    margin-top: 26px;
    padding: 20px;
    box-shadow: 0px 0px 18px $shadowColor;
  }
}
</style>